import React, { useState, useEffect } from 'react';
import { useCart } from 'react-use-cart';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import PotockLink from '../components/PotockLink';

// Animations
import gsap from 'gsap';
import { NEW_RECORD_PATH } from '../pages';

// Main Component
function Header({ shop }) {
  const [menuOpened, setMenuOpened] = useState(false);

  const { isEmpty } = useCart();

  // Animate fadeing in header
  useEffect(() => {
    gsap.to('header', {
      opacity: 1,
      duration: 3,
      delay: 3,
      ease: 'power4.out',
    });
  }, []);

  // Animate toggler on hover
  const onMouseEnterTogglerTimes = () => {
    gsap.to('#menu-toggler-times', { rotation: '90_cw' });
  };

  const onMouseLeaveTogglerTimes = () => {
    gsap.to('#menu-toggler-times', { rotation: '0_ccw' });
  };

  // Animate toggler on hover
  const onMouseEnterTogglerBars = () => {
    gsap.to('#menu-toggler-bars', { scaleX: 1.3 });
  };

  const onMouseLeaveTogglerBars = () => {
    gsap.to('#menu-toggler-bars', { scaleX: 1 });
  };

  const onMouseEnterCartIcon = () => {
    const run = gsap.timeline();
    run.set('#cart-menu-item-icon', { x: 0 });
    run.to('#cart-menu-item .cart-indicator', { opacity: 0, duration: 0.3 });
    run.to('#cart-menu-item-icon', { x: '200%' });
    run.fromTo('#cart-menu-item-icon', { x: '-200%' }, { x: 0 });
    run.to(
      '#cart-menu-item .cart-indicator',
      { opacity: 1, duration: 0.3 },
      '-=0.1'
    );
  };

  const toggleMenu = e => {
    e.preventDefault();

    // Init animation
    const t1 = gsap.timeline();
    if (!menuOpened) {
      // *Animate opening menu sidebar

      // Hide dots navigation for full page
      if (
        typeof document !== 'undefined' &&
        document.getElementById('fp-nav')
      ) {
        t1.to('#fp-nav', { duration: 0.15, opacity: 0 });
      }

      // Hide bars
      t1.to('#menu-toggler-bars', { opacity: 0, duration: 0.15 }).set(
        '#menu-toggler-bars',
        { display: 'none' }
      );

      // Draw out sidebar
      t1.fromTo(
        '.menu-sidebar',
        { x: '100%', display: 'none' },
        {
          ease: 'power4.inOut',
          delay: '=-0.2',
          duration: 0.5,
          x: '0%',
          display: 'block',
        }
      );

      // Animate whole container
      // t1.to("#___gatsby", { delay: -0.4, duration: .2, x: "-" + menuSidebarWidth, overflow: "hidden" });

      // Show menu items
      t1.fromTo(
        '.menu-sidebar li',
        { y: 10, opacity: 0 },
        { delay: -0.1, duration: 0.2, stagger: 0.1, y: 0, opacity: 1 }
      );

      // Show closing icon
      t1.set('#menu-toggler-times', { display: 'block', opacity: 0 }).to(
        '#menu-toggler-times',
        { opacity: 1, duration: 0.3 }
      );
    } else {
      // *Animate closing menu sidebar

      // Hide closing icon
      t1.to('#menu-toggler-times', { opacity: 0, duration: 0.3 }).set(
        '#menu-toggler-times',
        { display: 'none' }
      );

      // Hide menu items
      t1.fromTo(
        '.menu-sidebar li',
        { y: 0, opacity: 1 },
        {
          duration: 0.2,
          delay: -0.2,
          stagger: {
            from: 'end',
            each: 0.1,
          },
          y: 10,
          opacity: 0,
        }
      );

      // Hide menu sidebar
      t1.fromTo(
        '.menu-sidebar',
        { x: '0%', display: 'block' },
        {
          delay: -0.2,
          duration: 0.5,
          x: '100%',
          display: 'none',
          ease: 'power4.inOut',
        }
      );

      // Animate whole container
      // t1.to("#___gatsby", { delay: -0.4, duration: .2, x: "0", overflow: "hidden" });

      // Show bars
      t1.set('#menu-toggler-bars', { display: 'block', opacity: 0 }).to(
        '#menu-toggler-bars',
        { opacity: 1, duration: 0.15 }
      );

      // Show full page navigation
      if (
        typeof document !== 'undefined' &&
        document.getElementById('fp-nav')
      ) {
        t1.to('#fp-nav', { duration: 0.3, opacity: 1 });
      }
    }

    // Update state
    setMenuOpened(!menuOpened);
  };

  return (
    <div className="fixed w-full header ">
      <header className="px-6 w-full block flex-grow flex items-center w-auto justify-between opacity-0">
        <div className="w-full">
          <nav className="flex items-center justify-between flex-wrap">
            <PotockLink to="/">
              <img
                className="brand"
                src={'/logo-white.svg'}
                alt="Logo Potock"
              />
            </PotockLink>

            <div className="flex items-center">
              <div className="text-white border-b-2 border-transparent hover:border-white transition ease-in duration-200">
                <PotockLink to={NEW_RECORD_PATH}>Kup płytę</PotockLink>
              </div>

              <button
                type="button"
                className="menu-toggler text-white focus:outline-none ml-6 w-2 z-30 relative"
                onClick={toggleMenu}
              >
                {/* {menuOpened ? ( */}
                <FontAwesomeIcon
                  icon={faTimes}
                  id="menu-toggler-times"
                  className="hidden"
                  onMouseEnter={onMouseEnterTogglerTimes}
                  onMouseLeave={onMouseLeaveTogglerTimes}
                />
                {/*} ) : (*/}
                <FontAwesomeIcon
                  icon={faBars}
                  id="menu-toggler-bars"
                  className=""
                  onMouseEnter={onMouseEnterTogglerBars}
                  onMouseLeave={onMouseLeaveTogglerBars}
                />
                {/* )} */}
              </button>

              <ul className="menu-sidebar absolute bg-black left-0 right-0 top-0 bottom-0 h-screen text-2xl hidden pt-10 ">
                <li>
                  <PotockLink
                    className="py-3 block ease-in duration-200 text-center uppercase"
                    to="https://sklep.potock.pl"
                  >
                    Sklep
                  </PotockLink>
                </li>
                <li>
                  <PotockLink
                    className="py-3 block ease-in duration-200 text-center uppercase"
                    to="/zespol"
                  >
                    Zespół
                  </PotockLink>
                </li>
                <li>
                  <PotockLink
                    className="py-3 block ease-in duration-200 text-center uppercase"
                    to="/historia"
                  >
                    Historia
                  </PotockLink>
                </li>
                <li className="text-center text-lg absolute bottom-0 p-10 w-full block sm:flex justify-around">
                  <div className="text-center pb-4 md:w-1/4">
                    <h4 className="uppercase text-sm text-lightgray">
                      Kontakt
                    </h4>
                    <a
                      className="ease-in duration-200"
                      href="mailto:potock@potock.pl"
                    >
                      potock@potock.pl
                    </a>
                  </div>

                  <div className="text-center pb-4 md:w-1/4 flex flex-col">
                    <h4 className="uppercase text-sm text-lightgray">
                      Marta Stanko (manager)
                    </h4>
                    <a className="ease-in duration-200" href="tel:+48609738203">
                      +48 609 738 203
                    </a>
                  </div>

                  <div className="text-center pb-4 md:w-1/4">
                    <h4 className="uppercase text-sm text-lightgray">Sklep</h4>
                    <a
                      className="ease-in duration-200"
                      href="mailto:sklep@potock.pl"
                    >
                      sklep@potock.pl
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
